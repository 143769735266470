// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.turn-selector-turn-text {
    padding: 0px 8px;
}

.turn-selector {
    padding: 8px 5px;
}

.turn-selector button {
    margin: 0px 3px;
}`, "",{"version":3,"sources":["webpack://./src/ui/game_state/log_entry_selector.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".turn-selector-turn-text {\n    padding: 0px 8px;\n}\n\n.turn-selector {\n    padding: 8px 5px;\n}\n\n.turn-selector button {\n    margin: 0px 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
