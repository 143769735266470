// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectable-game {
    border: 1px solid #777;
    padding: 8px;
    margin: 10px 0px;
    cursor: pointer;
    transition: box-shadow 300ms;
}

.selectable-game:hover {
    box-shadow: 0px 0px 8px #777;
}`, "",{"version":3,"sources":["webpack://./src/ui/game_selector.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".selectable-game {\n    border: 1px solid #777;\n    padding: 8px;\n    margin: 10px 0px;\n    cursor: pointer;\n    transition: box-shadow 300ms;\n}\n\n.selectable-game:hover {\n    box-shadow: 0px 0px 8px #777;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
