// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    padding: 15px;
    border-radius: 15px;
    border: 3px solid;
    margin: 10px 0px;
}

.error {
    background: var(--tank-game-red);
    border-color: #a00;
    color: #fff;
}

.success {
    background: #0f0;
    border-color: #0a0;
}

.warning {
    background: #ff0;
    border-color: #aa0;
}`, "",{"version":3,"sources":["webpack://./src/ui/error_message.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".message {\n    padding: 15px;\n    border-radius: 15px;\n    border: 3px solid;\n    margin: 10px 0px;\n}\n\n.error {\n    background: var(--tank-game-red);\n    border-color: #a00;\n    color: #fff;\n}\n\n.success {\n    background: #0f0;\n    border-color: #0a0;\n}\n\n.warning {\n    background: #ff0;\n    border-color: #aa0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
