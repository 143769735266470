// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    position: absolute;
    background: #fff;
    box-shadow: 0px 0px 8px #000;
    transition: opacity 250ms;
    z-index: 100;
}

.popup-body {
    margin: 5px 8px;
}`, "",{"version":3,"sources":["webpack://./src/ui/generic/popup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".popup {\n    position: absolute;\n    background: #fff;\n    box-shadow: 0px 0px 8px #000;\n    transition: opacity 250ms;\n    z-index: 100;\n}\n\n.popup-body {\n    margin: 5px 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
