// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manual-popup {
    top: 25px;
    left: 25px;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background: #fff;
    border: 1px solid #333;
    position: fixed;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #000;
}

.manual-popup-title {
    background: #333;
    color: #fff;
    padding: 8px;
    display: flex;
}

.manual-popup-title h3 {
    flex-grow: 1;
}

.manual-popup-body {
    flex-grow: 1;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/ui/game-manual.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,wBAAwB;IACxB,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".manual-popup {\n    top: 25px;\n    left: 25px;\n    width: calc(100% - 50px);\n    height: calc(100% - 50px);\n    background: #fff;\n    border: 1px solid #333;\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0px 0px 8px #000;\n}\n\n.manual-popup-title {\n    background: #333;\n    color: #fff;\n    padding: 8px;\n    display: flex;\n}\n\n.manual-popup-title h3 {\n    flex-grow: 1;\n}\n\n.manual-popup-body {\n    flex-grow: 1;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
