// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pill {
    display: inline-block;
    padding: 6px 8px;
    border-radius: 30px;
}

.pill-secondary {
    padding: 3px 5px;
    border-radius: 30px;
    margin-left: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/game_state/attribute-list.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".pill {\n    display: inline-block;\n    padding: 6px 8px;\n    border-radius: 30px;\n}\n\n.pill-secondary {\n    padding: 3px 5px;\n    border-radius: 30px;\n    margin-left: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
