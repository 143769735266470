// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.armistice-clock circle {
    fill: transparent;
    stroke: #f00;
    stroke-width: 7;
}

.council-action-button {
    margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/ui/game_state/council.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".armistice-clock circle {\n    fill: transparent;\n    stroke: #f00;\n    stroke-width: 7;\n}\n\n.council-action-button {\n    margin-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
