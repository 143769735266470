// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/selected.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.board-space-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-board {
    user-select: none;
    border: 1px solid;
    display: inline-block;
}

.game-board-row {
    display: flex;
}

.board-space.board-space-cut {
    border: 2px dashed;
}

.board-space {
    border: 2px solid;
    width: var(--tank-game-cell-size);
    height: var(--tank-game-cell-size);
    flex-direction: column;
}

.board-space-unit-wrapper {
    width: 90%;
    height: 90%;
    border: 1px solid #000;
}

.board-space-coordinate {
    background: var(--tank-game-coordinate-bg);
}

.board-space-disabled {
    opacity: 0.3;
    background: #555;
}

.board-space-selectable {
    cursor: pointer;
}

.board-space-selected-overlay {
    width: 100%;
    height: 100%;
}

.board-space-overlay-selected {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/ui/game_state/board.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iCAAiC;IACjC,kCAAkC;IAClC,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mDAAsD;AAC1D","sourcesContent":[".board-space-centered {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.game-board {\n    user-select: none;\n    border: 1px solid;\n    display: inline-block;\n}\n\n.game-board-row {\n    display: flex;\n}\n\n.board-space.board-space-cut {\n    border: 2px dashed;\n}\n\n.board-space {\n    border: 2px solid;\n    width: var(--tank-game-cell-size);\n    height: var(--tank-game-cell-size);\n    flex-direction: column;\n}\n\n.board-space-unit-wrapper {\n    width: 90%;\n    height: 90%;\n    border: 1px solid #000;\n}\n\n.board-space-coordinate {\n    background: var(--tank-game-coordinate-bg);\n}\n\n.board-space-disabled {\n    opacity: 0.3;\n    background: #555;\n}\n\n.board-space-selectable {\n    cursor: pointer;\n}\n\n.board-space-selected-overlay {\n    width: 100%;\n    height: 100%;\n}\n\n.board-space-overlay-selected {\n    background: url('../../../public/assets/selected.png');\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
