// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.log-book-link {
    color: inherit;
    text-decoration: none;
    padding: 5px;
    display: block;
}

.log-entry:hover {
    background: #eee;
}

.log-entry.log-entry-current-turn {
    background: #1818a2;
    color: #fff;
}

.log-entry.log-entry-current-turn.log-entry-invalid {
    background: var(--tank-game-red);
    color: #000;
}

.log-book-day-heading {
    position: sticky;
    top: 0px;
    background: #333;
    color: #fff;
    margin-top: 0px;
    padding: 5px 8px;
}`, "",{"version":3,"sources":["webpack://./src/ui/game_state/log_book.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;IACrB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".log-book-link {\n    color: inherit;\n    text-decoration: none;\n    padding: 5px;\n    display: block;\n}\n\n.log-entry:hover {\n    background: #eee;\n}\n\n.log-entry.log-entry-current-turn {\n    background: #1818a2;\n    color: #fff;\n}\n\n.log-entry.log-entry-current-turn.log-entry-invalid {\n    background: var(--tank-game-red);\n    color: #000;\n}\n\n.log-book-day-heading {\n    position: sticky;\n    top: 0px;\n    background: #333;\n    color: #fff;\n    margin-top: 0px;\n    padding: 5px 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
